export const darkTheme = {
    bg: "#1A1B4C", // Dark blue
    bgLight: "#1C1E27",
    primary: "#FFA500", // Orange
    text_primary: "#F2F3F4",
    text_secondary: "#b1b2b3",
    card: "#3f3f74", // Blue
    card_light: "#191924",
    button: "#FFA500", // Orange
    white: "#FFFFFF",
    black: "#000000",
};

export const lightTheme = {
    bg: "#FFFFFF",
    bgLight: "#f0f0f0",
    primary: "#FFA500", // Orange
    text_primary: "#111111",
    text_secondary: "#48494a",
    card: "#4C5C7E", // Blue
    button: "#1A1B4C", // Dark blue
};
